<template>
    <div>
        <div class="modal fade modal-reg-package package" role="dialog">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content p-4">
                    <div class="package__header text-center">
                        <h4 class='modal-alert'>Đăng ký gói cước</h4>
                        <div>Vui lòng chọn gói cước phù hợp cần đăng ký để xem Clip</div>
                    </div>
                    <template v-for="info in package">
                        <div class="package">
                            <div class="package__title">{{ info.title }}</div>
                            <div class="package__description mb-2">{{ info.description }}</div>
                            <div class="package__info">
                                <div v-for="item in info.packageResponses" @click.prevent.self="selectPackage(item.packageDefault)" :class="item.packageDefault == packageSelect ? 'package-detail d-flex justify-content-between align-items-center selected' : 'package-detail d-flex justify-content-between align-items-center'">
                                    <div v-if="item.packageDefault == packageSelect" class="checked">
                                        <i class="ion ion-md-checkmark"></i>
                                    </div>
                                    <div class="left" @click.prevent="selectPackage(item.packageDefault)">
                                        <strong class="item-title">{{ item.title }}</strong>
                                        <div class="item-description">{{ item.description }}</div>
                                    </div>
                                    <div class="right" v-if="info.type != 2">
                                        <a href="#" @click.prevent="viewPackage(item)"><strong class="d-flex align-items-center">Xem <i class="ion ion-ios-arrow-forward ml-2"></i></strong></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <div class="text-center">
                        <button @click.prevent="getOtp" class="btn yc-btn yc-btn__rounded yc-btn--sm yc-btn__default text-uppercase">Đăng ký</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade modal-package-detail" role="dialog">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" v-if="packageDetail">
                    <div v-if="packageDetail" class="text-center position-relative">
                        <h4 class="item-title">{{ packageDetail.title }}</h4>
                        <div class="item-description">{{ packageDetail.description }}</div>
                        <i class="fa fa-times btn__close-modalPD" @click.prevent="closeModalPackageDetail"></i>
                    </div>
                    <template v-if="packageDetail">
                        <div class="package__info">
                            <div class="package__title mb-2">{{ packageDetail.packageResponse.title }}</div>
                            <div v-for="item in packageDetail.packageResponse.packages" @click.prevent.self="selectPackage(item)" :class="item.serviceCode == packageSelect.serviceCode ? 'package-detail d-flex justify-content-between align-items-center selected' : 'package-detail d-flex justify-content-between align-items-center'">

                                <div v-if="item.serviceCode == packageSelect.serviceCode" class="checked">
                                    <i class="ion ion-md-checkmark"></i>
                                </div>
                                <div class="left">
                                    {{ item.price.cycle+' ngày' }}
                                </div>
                                <div class="right">
                                    {{ thousandsSeparators(item.price.price)+' VNĐ' }}
                                </div>
                            </div>
                        </div>
                        <div class="contents">
                            <div class="mb-3" v-for="item in packageDetail.contents">
                                <div class="package__title">{{ item.title }}</div>
                                <div class="package__description" v-if="item.html && (item.html).length > 0" v-html="item.html"></div>
                                <div class="package__description" v-else>{{ item.description }}</div>
                            </div>
                        </div>
                    </template>
                    <div class="text-center">
                        <button @click.prevent="getOtp" class="btn yc-btn yc-btn__rounded yc-btn--sm yc-btn__default text-uppercase">Đăng ký</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade modal-otp" role="dialog">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content text-center">
                    <div class="otp-form">
                        <div class="form-title">
                            <h4>Xác nhận mã OTP</h4>
                            <div>Vui lòng nhập mã OTP bạn nhận được</div>
                        </div>
                        <div class="form-group">
                            <input class="form-control" value="" v-model="otpCode">
                        </div>
                        <div class="form-group">
                            <div class="text-center">
                                <button @click.prevent="registerPackage" class="btn yc-btn yc-btn__rounded yc-btn--sm yc-btn__default text-uppercase">Xác nhận</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PackageService",
        props : [
            'package'
        ],
        data(){
            return {
                packageSelect : '',
                packageDetail :'',
                showPackageInfo : false,
                otpCode : '',
            }
        },
        methods : {
            selectPackage(item){
               console.log(item);
                this.packageSelect = item;
            },
            thousandsSeparators(num){
                let num_parts = num.toString();
                return num_parts.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            },
            viewPackage(data){
                console.log(data);
                this.packageDetail = data;
                this.showPackageInfo = true;
                $('.modal-reg-package').modal('hide');
                $('.modal-package-detail').modal('show');
            },
            closeModalPackageDetail(){
                $('.modal-reg-package').modal('show');
                $('.modal-package-detail').modal('hide');
            },
            getOtp(){
                if (!this.packageSelect){
                    alert('Vui lòng chọn gói trước.');
                    return;
                }
                axios.get('/get-otp').then(response =>{
                    console.log(response);
                    // $('.modal-reg-package').modal('hide')
                    // $('.modal-package-detail').modal('hide')
                    // $('.modal-otp').modal('show');
                    if (response.data && response.data.code == 200) {
                        $('.modal-reg-package').modal('hide');
                        $('.modal-package-detail').modal('hide');
                        $('.modal-otp').modal('show');
                    } else {
                        alert(response.data.msg);
                        $('.modal-reg-package').modal('hide');
                        $('.modal-package-detail').modal('hide');
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            },
            registerPackage(){
                if (!this.otpCode){
                    alert('Vui lòng nhập mã OTP nhận được.');
                    return;
                }
                let data = {};
                data.action = 'REG';
                data.otp_code = this.otpCode;
                data.channel = 'web/wap';
                data.cycle_day = this.packageSelect.price.cycle;
                data.package_code = this.packageSelect.serviceCode;
                console.log(data);
                axios.post('/buy-package',data).then(response =>{
                    console.log(response);
                    if (response.data && response.data.code == 200) {
                        alert('Mua gói thành công.')
                    } else {
                        alert(response.data.msg);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            }
        }
    }
</script>

<style scoped>
    .contents{
        overflow-y: scroll;
        max-height: 200px;
    }
    .contents::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 5px;
        background-color:#141414;
    }
    .contents::-webkit-scrollbar {
        width: 5px;
        background-color: #141414;
    }
    .contents::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #000000;
    }
    .package__title{
        color: #00A1E4;
        font-weight: 700;
        font-size: 16px;
    }
    .package__title:before {
        content: '';
        width: 5px;
        height: 5px;
        display: inline-block;
        background-color: #00A1E4;
        border-radius: 50%;
        margin-right: 5px;
        margin-bottom: 3px;
    }
    .btn__close-modalPD{
        color: #909399;
        cursor: pointer;
        font-size: 18px;
        position: absolute;
        right: 0;
        top: 0;
    }
</style>
